String.prototype.printf = function (obj) {
  let useArguments = false;
  const _arguments = arguments;
  let i = -1;
  if (typeof _arguments[0] === "string") {
    useArguments = true;
  }
  if (obj instanceof Array || useArguments) {
    return this.replace(/%s/g,
      function () {
        i++;
        if (useArguments) {
          if (typeof _arguments[i] === 'string') {
            return _arguments[i];
          }
          else {
            throw new Error("Arguments element is an invalid type");
          }
        }
        return obj[i];
      });
  }
  else {
    return this.replace(/{([^{}]*)}/g,
      function (a, b) {
        const r = obj[b];
        return typeof r === 'string' || typeof r === 'number' ? r : a;
      });
  }
};

if (!String.prototype.format) {
  String.prototype.format = function() {
    let args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
        ;
    });
  };
}

if (!String.prototype.replaceSpecialChars) {
  String.prototype.replaceSpecialChars = function() {
    return this.replace(/ı/g, 'i')
      .replace(/ç/g, 'c')
      .replace(/ğ/g, 'g')
      .replace(/ş/g, 's')
      .replace(/ü/g, 'u')
      .replace(/ö/g, 'o');
  };
}

if(!Date.prototype.addDays){
  Date.prototype.addDays = function(days){
    let date;

    days = days || 0;

    if(days === 0){
      date = new Date( this.getTime() );
    } else if(days > 0) {
      date = new Date( this.getTime() );

      date.setDate(date.getDate() + days);
    } else {
      date = new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate() - Math.abs(days),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds()
      );
    }

    this.setTime(date.getTime());

    return this;
  };
}

if(!Date.prototype.addMonths){
  Date.prototype.addMonths = function(months){
    let date;

    months = months || 0;

    if(months === 0){
      date = new Date( this.getTime() );
    } else {
      date = new Date(
        this.getFullYear(),
        this.getMonth() + months,
        this.getDate(),
        this.getHours(),
        this.getMinutes(),
        this.getSeconds(),
        this.getMilliseconds()
      );
    }

    this.setTime(date.getTime());

    return this;
  };
}

if(!Date.prototype.startOfMonth){
  Date.prototype.startOfMonth = function(){
    let date = new Date(
      this.getFullYear(),
      this.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    this.setTime(date.getTime());

    return this;
  };
}


if(!Date.prototype.endOfMonth){
  Date.prototype.endOfMonth = function(){
    let date = new Date(
      this.getFullYear(),
      this.getMonth() + 1,
      0
    );

    this.setTime(date.getTime());

    return this;
  };
}
